import "../Navbar/components.css"
import React from "react";
import { useCartContext } from "../../context/CartContext";
export const CartWidget = () => {
  const{totalProducts }=useCartContext();

  return (
    <>
    <div> 
    <img className="Cart" src="https://cdn-icons-png.flaticon.com/512/3649/3649614.png" />

   
    </div>
    <span>{totalProducts()	|| "" }</span>
    
    </>
    )
};

export default CartWidget
