import React, { useState } from 'react';
import Contador from '../Contador/Contador';
import { useCartContext } from '../../context/CartContext';
import Swal from 'sweetalert2';

export const ItemDetail = ({ data }) => {
  
  const [imagenActual, setImagenActual] = useState(data.thumbnail);
  const [expandedDescription, setExpandedDescription] = useState(false);
  const { addProduct, cart } = useCartContext(); 
  const [stockDisponible, setStockDisponible] = useState(data.stock > 0); // Estado para controlar si hay stock disponible

  const previousImage = () => {
    const currentIndex = data.thumbnail === imagenActual ? 0 : data.thumbnail2 === imagenActual ? 1 : 2;
    const previousIndex = (currentIndex - 1 + 3) % 3;
    if (previousIndex === 0) {
      setImagenActual(data.thumbnail);
    } else if (previousIndex === 1) {
      setImagenActual(data.thumbnail2);
    } else {
      setImagenActual(data.thumbnail3);
    }
  };
  
  const toggleDescription = () => {
    setExpandedDescription(!expandedDescription);
  };
  
  const nextImage = () => {
    const currentIndex = data.thumbnail === imagenActual ? 0 : data.thumbnail2 === imagenActual ? 1 : 2;
    const nextIndex = (currentIndex + 1) % 3;
    if (nextIndex === 0) {
      setImagenActual(data.thumbnail);
    } else if (nextIndex === 1) {
      setImagenActual(data.thumbnail2);
    } else {
      setImagenActual(data.thumbnail3);
    }
  };
  
  const changeImage = (newImage) => {
    setImagenActual(newImage);
  };
  const onAdd = (cantidad) => {
    const existingProduct = cart.find(item => item.id === data.id);
    if (existingProduct) {
      const totalQuantity = existingProduct.cantidad + cantidad;
      if (totalQuantity <= data.stock) {
        addProduct(data, cantidad);
        Swal.fire({
          icon: 'success',
          title: 'Producto agregado',
          text: 'El producto ha sido agregado al carrito con éxito.',
          timer: 2000,
          showConfirmButton: false
        });
      } else {
        const remainingQuantity = data.stock - existingProduct.cantidad;
        if (remainingQuantity > 0) {
          addProduct(data, remainingQuantity);
          Swal.fire({
            icon: 'success',
            title: 'Producto agregado',
            text: 'El producto ha sido agregado al carrito con éxito.',
            timer: 2000,
            showConfirmButton: false
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Stock insuficiente',
            text: 'No hay suficiente stock disponible para esta cantidad.',
          });
        }
      }
    } else {
      if (cantidad <= data.stock) {
        addProduct(data, cantidad);
        Swal.fire({
          icon: 'success',
          title: 'Producto agregado',
          text: 'El producto ha sido agregado al carrito con éxito.',
          timer: 2000,
          showConfirmButton: false
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Stock insuficiente',
          text: 'No hay suficiente stock disponible para esta cantidad.',
        });
      }
    }
  };
  

  return (
    <div className='detalle-producto'>
      <div className='galeria-imagenes'>
        <img src={data.thumbnail2} alt={data.nombre} onClick={() => changeImage(data.thumbnail2)} />
        <img src={data.thumbnail3} alt={data.nombre} onClick={() => changeImage(data.thumbnail3)} />
      </div>
      <div className='imagen-principal'>
        <button className="flecha-izquierda" onClick={previousImage}></button>
        <img src={imagenActual} alt={data.nombre} />
        <button className="flecha-derecha" onClick={nextImage}></button>
      </div>
      <div className='descripcion'>
        <h2>{data.name}</h2>
        <h5>${data.price}</h5>
        {expandedDescription && <h5 style={{ color: 'black' }}>{data.description}</h5>}
        <div className='ver-mas' onClick={toggleDescription}>
          <h5>{expandedDescription ? 'Ver menos' : 'Ver más'}</h5>
        </div>
        {/* Mostrar botón de agregar al carrito solo si hay stock disponible */}
        {stockDisponible ? (
          <div className='contador2'>
            <Contador initial={1} stock={data.stock} onAdd={onAdd} />
          </div>
        ) : (
          <div className="sin-stock">Sin Stock</div>
        )}
      </div>
    </div>
  );
};

export default ItemDetail;
