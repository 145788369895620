import Toast from 'react-bootstrap/Toast';
function BasicExample({ orderId }) {
    return (
      <Toast>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Hello, world! This is a toast message for order ID {orderId}.</Toast.Body>
      </Toast>
    );
  }
  
  export default BasicExample;
  