import React from "react";
import { useCartContext } from "../../context/CartContext";
import "./cartitem2.css";

const CartItem2 = ({ item }) => {
    const {
        removeProduct,
        incrementProductQuantity,
        decrementProductQuantity,
    } = useCartContext();

    return (
        <div className="contenedor2">
            <div className="itemCart2">
                <div className="infoSection2">
                    <div className="productInfo2">
                        <img
                            className="ItemDetalle2"
                            src={item.thumbnail}
                            alt={item.name}
                        />
                        <div className="textoCart">
                            <h2 className="titleprod">{item.name}</h2>
                            <h4 className="quantityprod">
                            <button onClick={() => decrementProductQuantity(item.id)}>
                                -
                            </button>
                             {item.cantidad}
                            <button onClick={() => incrementProductQuantity(item.id)}>
                                +
                            </button>
                               
                            </h4>
                            <h4 className="price">$ {item.price}</h4>
                        </div>
                    </div>
                    <div className="botonelimina-container">
                        <div className="botonelimina">
                          
                            <button onClick={() => removeProduct(item.id)}>X</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem2;
