import React from 'react';
import Footer from './footer';
import vilmix from '../../assets/img/vilmix.jpeg';

const CambiosYDevoluciones = () => {
  return (
    <div className='cydev'>
    <div className='container-devolucion' >
      <div className="logo-container">
        <img src={vilmix} alt="Logo" className="logo" />
      </div>
    </div>
    <div className="data-container">
    <div className="cambiosdev">
        <div className='container-titlee'>
          <div>
            <h2 className='titulodev'>POLÍTICAS DE CAMBIO Y DEVOLUCIONES.</h2>
          </div>
          <div>
            <h3 className='devolucion'>Sitio web</h3>
            <p>
              Es importante tener claro que no hay tienda física. Las compras realizadas en la página web pueden cambiarse únicamente dentro de los 10 días de efectuarse la misma, con el respectivo cupón de cambios, packaging y con los productos en el mismo estado en que fueron entregados. <strong>No tienen cambio los productos de la categoría Aros (Cuff, piercing, Abridores) ya que los mismos son piezas sensibles de uso íntimo. No se aceptan cambios por descuido, uso indebido e irresponsable de la joya. Hay piezas que son sumamente sensibles y delicadas, por lo que requieren un trato acorde </strong>
            </p>
          </div>
          <div>
            <h3 className='devolucion'>Envíos</h3>
            <p>
              Los cambios de las joyas se pueden realizar por única vez dentro de los 10 días de recibido el paquete.
            </p>
            <p>
              Para ello tener en cuenta lo siguiente:
            </p>
            <p>El artículo deberá estar en perfectas condiciones, sin uso en su caja o envoltorio original y embalados en el mismo packaging recibido.</p>
            <p>Ambos envíos cerrarán a cuenta del cliente.</p>
            <p>No se realizan cambios del rubro aros (cuff, trepadores, piercing, abridores, etc.)</p>
            <p>No se aceptan cambios por el descuido, uso indebido e irresponsable de la joya. Hay piezas que son sumamente sensibles y delicadas por lo que requieren un trato acorde.</p>
            <p>Solo serán atendidos reclamos por piezas con fallas de fábrica que afecten la funcionalidad de la misma.</p>
            <p>Para gestionar el cambio completar el formulario correspondiente con los motivos que requiere el mismo: <a href="https://docs.google.com/forms/d/e/1FAIpQLSfRRYEnaSTamPACLOuD86Vi2VRfdTsLu7THILeECqEAKeZi1Q/viewform?vc=0&c=0&w=1&flr=0"><strong>Formulario de Cambio </strong></a></p>
            <p>El incumplimiento de cualquiera de las condiciones mencionadas será motivo suficiente para rechazar el cambio.</p>
            <p>La disponibilidad de stock depende del momento en el que se efectúe el cambio por lo que el cliente podrá elegir cualquier otro producto disponible. Si el costo de la nueva pieza elegida es mayor al de la compra original se deberá abonar la diferencia.</p>
          </div>
        </div>
        <h1>Accesorios Vilmix</h1>
      </div>
      
    </div>
    </div>
  );
};


export default CambiosYDevoluciones;
