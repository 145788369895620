import React, { useState, useContext, useEffect } from "react";

const CartContext = React.createContext([]);
export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem("cart");
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    const clearCart = () => {
        setCart([]);
        localStorage.removeItem("cart");
    };

    const removeProduct = (id) => {
        setCart(cart.filter(item => item.id !== id));
    };

    const incrementProductQuantity = (id) => {
        setCart(
            cart.map((item) => {
                if (item.id === id) {
                    if (item.cantidad + 1 > item.stock) {
                        alert('No hay suficiente stock disponible.');
                        return item;
                    }
                    return { ...item, cantidad: item.cantidad + 1 };
                }
                return item;
            })
        );
    };

    const decrementProductQuantity = (id) => {
        setCart(
            cart.map((item) =>
                item.id === id && item.cantidad > 1
                    ? { ...item, cantidad: item.cantidad - 1 }
                    : item
            )
        );
    };

    const addProduct = (item, cantidad) => {
        const existingProduct = cart.find((prod) => prod.id === item.id);
        if (existingProduct) {
            setCart(
                cart.map((prod) =>
                    prod.id === item.id
                        ? { ...prod, cantidad: prod.cantidad + cantidad }
                        : prod
                )
            );
        } else {
            setCart([...cart, { ...item, cantidad, stock: item.stock }]);
        }
    };
    

    const totalPrice = () => {
        return cart.reduce(
            (total, item) => total + item.price * item.cantidad,
            0
        );
    };

    const totalProducts = () => {
        return cart.reduce((total, item) => total + item.cantidad, 0);
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                clearCart,
                removeProduct,
                incrementProductQuantity,
                decrementProductQuantity,
                addProduct,
                totalPrice,
                totalProducts,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
