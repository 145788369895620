import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import CartCheckout from "../Cart/CartCheckout";
import axios from "axios";
import "./check.css";
import PlacementMultiExample from "./toast";
import vilmix from "../../assets/img/vilmix.jpeg";
import Swal from "sweetalert2"; // Importar SweetAlert2


const FuncionCompra = async (items, setPaymentCompleted) => {
  try {
    const result = await axios.post(
      "https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/payment/mercado_pago",
      items
    );
    window.location.href = result.data.initPoint;
  } catch (error) {
    console.error(error);
  } finally {
    setPaymentCompleted(true);
  }
};

const Checkout = () => {
  const navigate = useNavigate();
  const { cart, totalPrice, clearCart } = useCartContext();
  const [isPaymentCompleted, setPaymentCompleted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); // NUEVA LINEA
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);

  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    celular: "",
    tipoEntrega: "",
    metodoPago: "",
    provincia: "",
    ciudad: "",
    calle: "",
    numero: "",
    piso: "",
    entrecalles: "",
    codigoPostal: ""
  });
  const [showAddressForm, setShowAddressForm] = useState(false);

  useEffect(() => {
    if (formData.metodoPago === "Transferencia") {
      setPaymentCompleted(true); // Habilitar el botón si se elige transferencia
    }
  }, [formData.metodoPago]);
  useEffect(() => {
    validateForm(); // NUEVA LINEA
  }, [formData]); // NUEVA LINEA

  const [orderId, setOrderId] = useState(null);
  const totalNormal= totalPrice();

  // Actualiza el cálculo del total para aplicar el descuento
  const totalDescuento = totalPrice() - (totalPrice() * discount / 100);

  const order = {
    items: cart.map((item) => ({
      id: item.id,
      nombre: item.name,
      precio: item.price,
      cantidad: item.cantidad,
    })),
    total:totalNormal,
    totalDescuento: totalDescuento,
    ...formData,
  };

  const handleTipoEntregaChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, tipoEntrega: value });
    setShowAddressForm(value === "envio");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await axios.post(
        "https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/validate",
        { couponCode }
      );
      // Asegúrate de que el campo de respuesta es 'discount'
      if (response.data && response.data.amount) {
        setDiscount(response.data.amount);
      } else {
        alert("Cupón inválido o sin descuento");
      }
    } catch (error) {
      console.error("Error al aplicar el cupón:", error);
      alert("Cupón inválido");
    }
  };

  const handleCheckout = async () => {
    const updatedOrder = {
      items: order.items,
      total: discount > 0 ? totalDescuento : totalNormal, 
      totalSinDescuento: totalNormal, // Usa el total con descuento si hay un descuento
      nombre: order.nombre,
      apellido: order.apellido,
      dni: order.dni,
      celular: order.celular,
      tipoEntrega: order.tipoEntrega,
      metodoPago: order.metodoPago,
    };
  
    if (discount > 0) {
      updatedOrder.couponCode = couponCode; // Solo agrega el código de cupón si hay un descuento
    }
  
    if (formData.tipoEntrega === "envio") {
      updatedOrder.direccionEnvio = {
        provincia: formData.provincia,
        ciudad: formData.ciudad,
        calle: formData.calle,
        numero: formData.numero,
        piso: formData.piso,
        entrecalles: formData.entrecalles,
        codigoPostal: formData.codigoPostal,
      };
    }

    try {
      const insufficientStock = await checkStock(cart);
      if (insufficientStock) {
        alert(
          "No hay suficiente stock disponible para algunos productos en el carrito. Por favor, revisa tu pedido."
        );
        return;
      }

      if (formData.metodoPago === "Mercado Pago") {
        await FuncionCompra(updatedOrder.items, setPaymentCompleted);
      } else if (formData.metodoPago === "Transferencia") {
        navigate("/transferencia");
      }

      const response = await axios.post(
        "https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders",
        updatedOrder,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      clearCart();
      //console.log("Respuesta del servidor:", response.data);
      setOrderId(response.data.orderId);
      //console.log("Items de la orden:", order.items);
      Swal.fire({
        title: "Compra exitosa",
        text: `Número de orden: ${response.data.orderId}`,
        icon: "success",
        confirmButtonText: "OK"
      });

      // Actualizar el stock del producto después de la compra
      await axios.put(
        "https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products/stock-after-buy",
        order.items
      );

    } catch (error) {
      console.error("Error al procesar la orden:", error);
    }
  };

  const checkStock = async (cart) => {
    try {
      const promises = cart.map((item) =>
        axios.get(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products/productos/${item.id}`)
      );
      const responses = await Promise.all(promises);
      const products = responses.map((response) => response.data);
      const insufficientStock = products.some(
        (product, index) => product.stock < cart[index].cantidad
      );

      return insufficientStock;
    } catch (error) {
      console.error("Error al verificar el stock:", error);
      throw error;
    }
  };
  const validateForm = () => { // NUEVA LINEA
    const isValid = formData.nombre && formData.apellido && formData.dni && formData.celular && // NUEVA LINEA
      formData.tipoEntrega && formData.metodoPago && // NUEVA LINEA
      (formData.tipoEntrega === "retiro" || (formData.provincia && formData.ciudad && formData.calle && // NUEVA LINEA
        formData.numero && formData.codigoPostal)); // NUEVA LINEA
    setIsFormValid(isValid); // NUEVA LINEA
  }; // NUEVA LINEA

  return (
    <div className="shippin-order">
      <div className="shippin">
        <div className="logo-container">
          <img src={vilmix} alt="Logo" className="logo" />
        </div>
        <div className="checkout-container">
          <div className="cart-and-form-container">
            <div className="checkout-form">
              <div className="form-block">
                <h2>Datos Personales</h2>
                <form>
                  <label>
                    Nombre:
                    <input
                      type="text"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    Apellido:
                    <input
                      type="text"
                      name="apellido"
                      value={formData.apellido}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    DNI:
                    <input
                      type="text"
                      name="dni"
                      value={formData.dni}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    Celular:
                    <input
                      type="text"
                      name="celular"
                      value={formData.celular}
                      onChange={handleInputChange}
                    />
                  </label>
                </form>
              </div>
              <div className="form-block">
                <h2>¿Dónde entregamos tu compra?</h2>
                <form>
                  <div className="labels">
                    <label className="radio-container">
                      <input
                        type="radio"
                        name="tipoEntrega"
                        value="retiro"
                        checked={formData.tipoEntrega === "retiro"}
                        onChange={handleTipoEntregaChange}
                      />
                      <span className="radio-label">
                        <img
                          className="icons"
                          src="https://cdn.icon-icons.com/icons2/1580/PNG/512/2849808-interface-multimedia-pack-package-shop_107971.png"
                          alt="Retiro"
                        />
                        Retiro
                      </span>
                    </label>
                    <label className="radio-container">
                      <input
                        type="radio"
                        name="tipoEntrega"
                        value="envio"
                        checked={formData.tipoEntrega === "envio"}
                        onChange={handleTipoEntregaChange}
                      />
                      <span className="radio-label">
                        <img
                          className="icons"
                          src="https://cdn.icon-icons.com/icons2/2622/PNG/512/gui_shipping_icon_157590.png"
                          alt="Envio"
                        />
                        Envío
                      </span>
                    </label>
                  </div>
                  {formData.tipoEntrega === 'retiro' && (
                    <p>Punto de encuentro a coordinar en microcentro</p>
                  )}
                  {formData.tipoEntrega === 'envio' && (
                    <p>El valor del envio es a coordinar por whatsapp</p>
                  )}
                  {showAddressForm && (
                    <>
                      <label>
                        Provincia:
                        <select
                          name="provincia"
                          value={formData.provincia}
                          onChange={handleInputChange}
                        >
                          <option value="">Seleccione una provincia</option>
                          <option value="Buenos Aires">Buenos Aires</option>
                          {/* Añadir otras provincias según sea necesario */}
                        </select>
                      </label>
                      <label>
                        Ciudad:
                        <input
                          type="text"
                          name="ciudad"
                          value={formData.ciudad}
                          onChange={handleInputChange}
                        />
                      </label>
                      <label>
                        Calle:
                        <input
                          type="text"
                          name="calle"
                          value={formData.calle}
                          onChange={handleInputChange}
                        />
                      </label>
                      <label>
                        Número:
                        <input
                          type="text"
                          name="numero"
                          value={formData.numero}
                          onChange={handleInputChange}
                        />
                      </label>
                      <label>
                        Piso/Dpto:
                        <input
                          type="text"
                          name="piso"
                          value={formData.piso}
                          onChange={handleInputChange}
                        />
                      </label>
                      <label>
                        Entre calles:
                        <input
                          type="text"
                          name="entrecalles"
                          value={formData.entrecalles}
                          onChange={handleInputChange}
                        />
                      </label>
                      <label>
                        Código Postal:
                        <input
                          type="text"
                          name="codigoPostal"
                          value={formData.codigoPostal}
                          onChange={handleInputChange}
                        />
                      </label>
                    </>
                  )}
                </form>
              </div>
              <div className="form-block">
                <h2>¿Cómo te gustaría abonar?</h2>
                <form>
                  <div className="labels">
                    <label className="radio-container">
                      <input
                        type="radio"
                        name="metodoPago"
                        value="Mercado Pago"
                        checked={formData.metodoPago === "Mercado Pago"}
                        onChange={handleInputChange}
                      />
                      <span>
                        <img
                          className="icons"
                          src="https://cdn.icon-icons.com/icons2/3913/PNG/512/mercadopago_logo_icon_248494.png"
                          alt="MP"
                        />{" "}
                        Mercado Pago
                      </span>
                    </label>
                    <label className="radio-container">
                      <input
                        type="radio"
                        name="metodoPago"
                        value="Transferencia"
                        checked={formData.metodoPago === "Transferencia"}
                        onChange={handleInputChange}
                      />
                      <span>
                        <img
                          className="icons"
                          src="https://cdn.icon-icons.com/icons2/2248/PNG/512/bank_transfer_icon_137913.png"
                          alt="Transferencia"
                        />
                        Transferencia
                      </span>
                    </label>
                  </div>
                </form>
              </div>
              <button onClick={handleCheckout} disabled={!isFormValid}>Terminar Compra</button> {/* MODIFICADA */}
            </div>
            <div className="cart-items">
              <strong>Resumen de compra</strong>
              {cart.map((item) => (
                <CartCheckout key={item.id} item={item} />
              ))}
              <div className="center-content">
                <h2>¿Tienes un cupón?</h2>
                <form>
                  <label>
                    <input
                      type="text"
                      value={couponCode}
                      onChange={handleCouponChange}
                    />
                  </label>
                  <button type="button" onClick={handleApplyCoupon}>
                    Aplicar Cupón
                  </button>
                </form>
              </div>
              <div className="total-price">
                <strong>Total: </strong>
                {discount > 0 ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      ${totalPrice()} 
                    </span>{" "}
                    <strong>${totalDescuento.toFixed(2)} </strong>
                  </>
                ) : (
                  <strong>${totalPrice()} </strong>
                )}
              </div>
            </div>
          </div>
        </div>
        {orderId && <PlacementMultiExample orderId={orderId} />}
      </div>
    </div>
  );
};

export default Checkout;
