import React from 'react';
import imagenTransferencia from "../../assets/img/WhatsApp Image 2024-04-07 at 15.06.10.jpeg";
import vilmix from '../../assets/img/vilmix.jpeg';

const PreguntasFrecuentes = () => {
  return (
    <div style={{ backgroundColor: 'white' }}>


    <div className="logo-container">
        <img src={vilmix} alt="Logo" className="logo" />
      </div>
    <div className='container-title'>
      <div className="data-container">
        <div className="faq">
          <h2>Preguntas frecuentes</h2>
          <div className="pregunta">
            <h2>¿Cómo comprar?</h2>
            <p>Para comprar en nuestra tienda en línea, sigue estos pasos:</p>

            <p>1. Elige los productos que deseas comprar y haz clic en el botón "Agregar al carrito".</p>
            <p>2. Completa tus datos de contacto y haz clic en "Continuar".</p>
            <p>3. Selecciona el medio de pago que prefieras, como Mercado Pago, que incluye tarjetas de crédito, débito y transferencias bancarias.</p>
            <p>4. Confirma tu compra y recibirás un correo electrónico como comprobante de tu pedido.</p>
            <p>5. En el caso de envíos, tu pedido será despachado dentro de las 24/48 horas hábiles luego de acreditado el pago. Recibirás un código de seguimiento para ver el estado de tu paquete hasta que llegue a destino.</p>
   
          </div>
          <div className="pregunta">
            <h3>¿Cuáles son los medios de pago?</h3>
            <p>Los medios de pago disponibles son Mercado Pago, que incluye tarjetas de crédito, débito y transferencias bancarias.</p>
          </div>
          <div className="pregunta">
            <h3>¿Cuál es el tiempo de entrega?</h3>
            <p>El tiempo de entrega varía dependiendo de la ubicación y el tipo de envío. En general, los pedidos son despachados dentro de las 24/48 horas hábiles luego de acreditado el pago.</p>
          </div>
          <div className="pregunta">
            <h3>¿Cuál es el monto mínimo de compra?</h3>
            <p>El monto mínimo de compra es de 4000 pesos.</p>
          </div>
         
        </div>
        <h1>Accesorios Vilmix</h1>
      </div>
    </div>
    </div>

  );
};

export default PreguntasFrecuentes;
