import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import './login.css'; // Importa los estilos CSS

function RestorePassword() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(""); // Nuevo estado para el mensaje de bienvenida
  const navigate = useNavigate();


  useEffect(()=>{
    if(!window.location.pathname.includes(localStorage.resetToken)){
      navigate('/')
    }

  })
  function handleSubmit(event) {
    event.preventDefault();
    axios.post('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/users/restore-password', { email, password, confirmPassword })
      .then(res => {
        setMessage(res.data.message)
        localStorage.removeItem('resetToken')
        setTimeout(()=>{
          navigate('/login')
        }, 3000)
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <div className="login-container">
        <p>{message}</p>
        <h2>Cambia tu contraseña</h2>
        <form onSubmit={handleSubmit} id="restore-password">
          <div>
            <label>Email:</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div>
            <label>Contraseña:</label>
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
          </div>
          <div>
            <label>Repite tu contraseña:</label>
            <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          </div>
      <button type="submit">Restaurar contraseña</button>
        </form>
      </div>
    </>
  );
}

export default RestorePassword;
