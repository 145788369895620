import React from "react";
import { useCartContext } from "../../context/CartContext";
import './cart.css';

const CartItem = ({ item }) => {
    const { removeProduct,    

        incrementProductQuantity,
        decrementProductQuantity,
    } = useCartContext(); 

    return (
        
        <tr className="itemCart" >
            <td className="infoSection">
                <img className="ItemDetalle2" src={item.thumbnail} alt={item.name}/>
            </td>
            <td className="infoSection" >
                <h6>{item.name}</h6>
            </td>
            <td className="infoSection" >
                <h6>${item.price}</h6>
            </td>
            <td className="infoSection" >
            <h6>
            <button onClick={() => decrementProductQuantity(item.id)}>
                                -
                            </button>
                           {item.cantidad}
                            <button onClick={() => incrementProductQuantity(item.id)}>
                                +
                            </button>
                            </h6>
             
            </td>
            <td className="infoSection" >
                <h6>${item.price * item.cantidad}</h6>
            </td>
            <td className="infoSection">
                <div className="botonelimina">
                    <button onClick={() => removeProduct(item.id)}>X</button>
                </div>
            </td>
        </tr>
    ); 
};

export default CartItem;
