import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import imagenTransferencia from "../../assets/img/pulsera.jpg"; // Importa la imagen de transferencia
import Navbar from "../Navbar/Navbar";
const TransferenciaDatos = () => {

    return (
        <>
            <Navbar/>
            <div className="container-transferencia">
                <div className="imagen-container">
                    <img src={imagenTransferencia} alt="Imagen de transferencia" />
                </div>
                <div className="data-container">
                <h1>
                        <span>Gracias por tu compra!</span>
                        <span>El comprobante con el numero de orden llegara al tu email!</span>
                    </h1> <br /> <br />

                    <h2>Datos para transferir</h2>
                    <h1>CVU: 0000003100075188155620</h1>
                    <h1>Alias: ruta.coreo.coco.mp</h1>
                    <h1>Nombre: Vilma Daniela Paola Vasquez Rojas</h1>
                    <p>Luego de realizar la transferencia, enviar el comprobante por WhatsApp.</p>
                    <a href="https://wa.me/5491164072697?text=Hola%20mi%20n%C3%BAmero%20de%20orden%20es%20...
" target="_blank" rel="noopener noreferrer">

                <button>
                    <FontAwesomeIcon icon={faWhatsapp} /> Ir a WhatsApp
                </button>
            </a>
                </div>
                
            </div>
            
        </>
    );
}

export default TransferenciaDatos;
