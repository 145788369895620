import React from "react";
import { useCartContext } from "../../context/CartContext";
import './Cartcheck.css'

const CartCheckout = ({ item }) => {
    const { removeProduct } = useCartContext();

    return (
        <div className="contenedor2">
            <div className="itemCart2">
                <div className="infoSection2">
                    <div className="productInfo2">
                        <img className="ItemDetalle2" src={item.thumbnail} alt={item.name} />
                        <div className="textoCart">
                            <h2 className="titleprodcheck">{item.name}</h2>
                            <div className="quantitypricecheck">
                            <p className="quantitypricecheck">Cantidad: {item.cantidad}    ${item.price}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartCheckout;
