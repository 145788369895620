import React, { useState, useEffect } from "react";
import axios from "axios";
import './products.css'

function AddProd() {
  const [name, setname] = useState("");
  const [thumbnail, setImagen] = useState("");
  const [thumbnail2, setImagen2] = useState("");
  const [thumbnail3, setImagen3] = useState("");
  const [stock, setCantidad] = useState("");
  const [description, setDescripcion] = useState("");
  const [price, setPrecio] = useState("");
  const [category, setCategory] = useState("");
  const [subcategoria, setSubCategory] = useState("");
  const [error, setError] = useState("");
  const [productos, setProductos] = useState([]);
  const [productoAEliminar, setProductoAEliminar] = useState("");
  const [editProductId, setEditProductId] = useState(null);


 
  const cargarProductos = async () => {
    try {
      const response = await axios.get('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products');
      setProductos(response.data);
    } catch (error) {
      console.error('Error al cargar los productos:', error);
    }
  };

 
  useEffect(() => {
    cargarProductos();
  }, []);

  const handleAddProduct = (e) => {
    e.preventDefault();

   
    axios.post('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products/productos', {name, thumbnail,thumbnail2,thumbnail3, stock, description, price, category ,subcategoria})
      .then(res => {
       // console.log(res.data); 
       
        cargarProductos();
      })
      .catch(err => {
        console.error(err);
        setError("Error al agregar el producto");
      });
  };

  const handleDeleteProduct = (productId) => {
  
    axios.delete(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products/productos/${productId}`)
      .then(res => {
     //   console.log(res.data);
      
        cargarProductos();
      })
      .catch(err => {
        console.error(err);
        setError("Error al eliminar el producto");
      });
  };

  const handleEditProduct = (e) => {
    e.preventDefault();

    axios
      .put(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products/productos/${editProductId}`, {
        name,
        thumbnail,
        thumbnail2,
        thumbnail3,
        stock,
        description,
        price,
        category,
        subcategoria,
      })
      .then((res) => {
     //   console.log(res.data);
        cargarProductos();
        setEditProductId(null);
      })
      .catch((err) => {
        console.error(err);
        setError("Error al editar el producto");
      });
  };

  const handleEditClick = (productId) => {
    const productToEdit = productos.find((producto) => producto.id === productId);
    setname(productToEdit.name);
    setImagen(productToEdit.thumbnail);
    setImagen2(productToEdit.thumbnail2);
    setImagen3(productToEdit.thumbnail3);
    setCantidad(productToEdit.stock);
    setDescripcion(productToEdit.description);
    setPrecio(productToEdit.price);
    setCategory(productToEdit.category);
    setEditProductId(productId);
    setSubCategory(subcategoria.id);
  };
  const userRole = localStorage.getItem('userRole');
 // console.log(userRole)



  // Verifica el rol del usuario y muestra el componente solo si es un administrador
  if (userRole !== "admin") {
    return <p>No tienes permiso para acceder a esta página.</p>;
  }
  return (
    <div>
        <h2 style={{ marginTop: "80px" }}>Agregar Producto</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleAddProduct}>
        <div>
          <label htmlFor="name">Producto:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setname(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="imagen">URL de la imagen:</label>
          <input
            type="text"
            id="imagen"
            value={thumbnail}
            onChange={(e) => setImagen(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="imagen">URL de la imagen 2:</label>
          <input
            type="text"
            id="imagen"
            value={thumbnail2}
            onChange={(e) => setImagen2(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="imagen">URL de la imagen 3:</label>
          <input
            type="text"
            id="imagen"
            value={thumbnail3}
            onChange={(e) => setImagen3(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="cantidad">Stock:</label>
          <input
            type="number"
            id="cantidad"
            value={stock}
            onChange={(e) => setCantidad(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="descripcion">Descripción:</label>
          <textarea
            id="descripcion"
            value={description}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="precio">Precio:</label>
          <input
            type="number"
            id="precio"
            value={price}
            onChange={(e) => setPrecio(e.target.value)}
            required
          />
        </div>
        <div>
  <label htmlFor="category">Categoría:</label>
  <select
    id="category"
    value={category}
    onChange={(e) => setCategory(e.target.value)}
    required
  >
    <option value="">Selecciona una categoría</option>
    <option value="Pulsera">Pulsera</option>
    <option value="Cadenita">Cadenita</option>
    <option value="Collar">Collar</option>
    <option value="Cuff">Cuff</option>
    <option value="Aros">Aro</option>
    <option value="Reloj">Reloj</option>
    <option value="Anillo">Anillo</option>
    <option value="Tobillera">Tobillera</option>
    <option value="Esclavas">Esclavas</option>  
    <option value="Abridores">Abridores</option>
    <option value="nuevosingresos">Nuevos Ingresos</option>
    <option value="Hombre">Hombres</option>
    <option value="Lenceria">Lenceria</option>
  </select>
</div> <div>
  <label htmlFor="Subcategory">Subategoría:</label>
  <select
    id="subcategoria"
    value={subcategoria}
    onChange={(e) => setSubCategory(e.target.value)}
    required
  >
    <option value="">Selecciona una  sub categoría</option>
    <option value="plata925">Plata 925</option>
    <option value="Aceroquirurgico">Acero quirurgico</option>
    <option value="Aceroblanco">Acero blanco</option>    
    <option value="Acerodorado">Acero dorado</option>
 
  </select>
</div>

        <button type="submit">Agregar Producto</button>
      </form>
      {/* Mostrar la lista de productos */}
      <h2 style={{ marginTop: "20px" }}>Lista de Productos</h2>

<div className="product-container">
  {productos.map((producto) => (
    <div className="product-card" key={producto.id}>
      <img className="product-thumbnail" src={producto.thumbnail} alt="Thumbnail del producto" />
      <div className="product-info">
        <p className="product-name">{producto.name}</p>
        <p className="product-description">{producto.description}</p>
        <p className="product-stock">Stock: {producto.stock}</p>
        <p className="product-price">Precio: {producto.price}</p>
        <div className="product-buttons">
          <button onClick={() => handleDeleteProduct(producto.id)}>Eliminar</button>
          <button onClick={() => handleEditClick(producto.id)}>Editar</button>
        </div>
      </div>
    </div>
  ))}
</div>
      {editProductId && (
        <div>
          <h2 style={{ marginTop: "80px" }}>Editar Producto</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <form onSubmit={handleEditProduct}>
            <div>
              <label htmlFor="name">Nombre:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="imagen">URL de la imagen:</label>
              <input
                type="text"
                id="imagen"
                value={thumbnail}
                onChange={(e) => setImagen(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="cantidad">Cantidad:</label>
              <input
                type="number"
                id="cantidad"
                value={stock}
                onChange={(e) => setCantidad(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="descripcion">Descripción:</label>
              <textarea
                id="descripcion"
                value={description}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="precio">Precio:</label>
              <input
                type="number"
                id="precio"
                value={price}
                onChange={(e) => setPrecio(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="category">Categoría:</label>
              <input
                type="text"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
              
            </div>       <div>
  <label htmlFor="category">Categoría:</label>
  <select
    id="category"
    value={category}
    onChange={(e) => setCategory(e.target.value)}
    required
  >
    <option value="">Selecciona una categoría</option>
    <option value="Pulsera">Pulsera</option>
    <option value="Cadenita">Cadenita</option>
    <option value="Collar">Collar</option>
    <option value="Cuff">Cuff</option>
    <option value="Aros">Aro</option>
    <option value="Reloj">Reloj</option>
    <option value="Anillo">Anillo</option>
    <option value="Tobillera">Tobillera</option>
    <option value="Esclavas">Esclavas</option>  
    <option value="Abridores">Abridores</option>
    <option value="nuevosingresos">Nuevos Ingresos</option>
    <option value="Hombre">Hombres</option>
    <option value="Lenceria">Lenceria</option>
  </select>
</div>
            <div>
  <label htmlFor="Subcategory">Subategoría:</label>
  <select
    id="subcategoria"
    value={subcategoria}
    onChange={(e) => setSubCategory(e.target.value)}
    required
  >
    <option value="">Selecciona una  sub categoría</option>
    <option value="plata925">Plata 925</option>
    <option value="Aceroquirurgico">Acero quirurgico</option>
    <option value="Aceroblanco">Acero blanco</option>    
    <option value="Acerodorado">Acero dorado</option>
 
  </select>
</div>
            <button type="submit">Editar Producto</button>
          </form>
        </div>
      )}
    </div>
  );
}
  

export default AddProd;
