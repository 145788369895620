import React from 'react';
import { Link } from 'react-router-dom'; // Importa el componente Link para manejar la navegación
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Estilos CSS del carrusel
import Header from '../Header/header';
import image1 from '../../assets/img/pulsera.jpeg';
import image2 from '../../assets/img/reloj.jpeg';
import image3 from '../../assets/img/tobillera.jpeg';
import image4 from '../../assets/img/cadenita.jpeg';
import image5 from '../../assets/img/cadena2.jpeg';
import image6 from '../../assets/img/aros.jpeg';
import image7 from '../../assets/img/abridores.webp';
import image8 from '../../assets/img/cuff.jpeg';
import image9 from '../../assets/img/583negromasart91negro.jpg';
import image10 from '../../assets/img/hombres2.webp';
import image11 from '../../assets/img/esclavas.webp';
import image12 from '../../assets/img/anillo.webp';
import NavScrollExample from '../Navbar/Navbar';
import './home.css' // Archivo CSS para estilos adicionales
import CarouselComponent from './carrousel';
const Home = () => {
  return (
    
    <div className='casa'>
           <NavScrollExample/>
            <Header/>
    

      <div className='carousel-container'>
        <CarouselComponent />
      </div>
      <div className="image-container">
        <Link to="/ItemListContainer/pulsera" className="image-link">
          <img src={image1} alt="Image 1" className="image" />
          <div className="text">Pulseras</div>
        </Link>
        <Link to="/ItemListContainer/relojes" className="image-link">
          <img src={image2} alt="Image 2" className="image" />
          <div className="text">Relojes</div>
        </Link>
        <Link to="/ItemListContainer/tobillera" className="image-link">
          <img src={image3} alt="Image 3" className="image" />
          <div className="text">Tobilleras</div>
        </Link>
        <Link to="/ItemListContainer/cadenita" className="image-link">
          <img src={image5} alt="Image 5" className="image" />
          <div className="text">Cadenitas</div>
        </Link>
        <Link to="/ItemListContainer/aros" className="image-link">
          <img src={image6} alt="Image 6" className="image" />
          <div className="text">Aros</div>
        </Link>
        <Link to="/ItemListContainer/abridores" className="image-link">
          <img src={image7} alt="Image 7" className="image" />
          <div className="text">Abridores</div>
        </Link>
        <Link to="/ItemListContainer/cuff" className="image-link">
          <img src={image8} alt="Image 8" className="image" />
          <div className="text">Cuff</div>
        </Link>
        <Link to="/ItemListContainer/lenceria" className="image-link">
          <img src={image9} alt="Image 9" className="image" />
          <div className="text">Lenceria</div>
        </Link>
      
        <Link to="/ItemListContainer/esclavas" className="image-link">
          <img src={image11} alt="Image 11" className="image" />
          <div className="text">Esclavas</div>
        </Link>
       

      </div>
      <div>
      <div>
    
      </div>
      </div>
    </div>
  );
};

export default Home;

