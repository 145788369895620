import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';

const CouponManager = () => {
  const [coupons, setCoupons] = useState([]);
  const [newCoupon, setNewCoupon] = useState({ code: '', expiryDate: '', amount: '' });
  const [editCoupon, setEditCoupon] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await axios.get('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/coupons'); // Ajusta la URL según tu API
      setCoupons(response.data);
    } catch (err) {
      setError('Error fetching coupons');
    }
  };

  const createCoupon = async () => {
    try {
      await axios.post('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/coupons', newCoupon); // Ajusta la URL según tu API
      fetchCoupons(); // Refresca la lista de cupones
      setNewCoupon({ code: '', expiryDate: '', amount: '' });
    } catch (err) {
      setError('Error creating coupon');
    }
  };

  const updateCoupon = async (id, updatedCoupon) => {
    try {
      await axios.put(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/coupons/${id}`, updatedCoupon); // Ajusta la URL según tu API
      fetchCoupons(); // Refresca la lista de cupones
      setEditCoupon(null);
    } catch (err) {
      setError('Error updating coupon');
    }
  };

  const deleteCoupon = async (id) => {
    try {
      await axios.delete(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/coupons/${id}`); // Ajusta la URL según tu API
      fetchCoupons(); // Refresca la lista de cupones
    } catch (err) {
      setError('Error deleting coupon');
    }
  };

  const handleEditClick = (coupon) => {
    setEditCoupon(coupon);
  };
  const userRole = localStorage.getItem('userRole');
  if (userRole !== "admin") {
    return <p>No tienes permiso para acceder a esta página.</p>;
  }
  return (
    <div>
      <h2>Cupones</h2>

      {error && <div>{error}</div>}

      <ul>
        {coupons.map((coupon) => (
          <li key={coupon.id}>
            {coupon.code} - Vencimiento: {coupon.expiryDate} - Descuento: {coupon.amount}%
            <button onClick={() => handleEditClick(coupon)}>
              <FaEdit />
            </button>
            <button onClick={() => deleteCoupon(coupon.id)}>
              <FaTrash />
            </button>
          </li>
        ))}
      </ul>

      <h3>Crear nuevo cupón</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createCoupon();
        }}
      >
        <div>
          <label>
            Code:
            <input
              type="text"
              value={newCoupon.code}
              onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>
            Vencimiento:
            <input
              type="date"
              value={newCoupon.expiryDate}
              onChange={(e) => setNewCoupon({ ...newCoupon, expiryDate: e.target.value })}
            />
          </label>
        </div>
        <div>
          <label>
            Descuento %:
            <input
              type="number"
              value={newCoupon.amount}
              onChange={(e) => setNewCoupon({ ...newCoupon, amount: e.target.value })}
            />
          </label>
        </div>
        <button type="submit">Crear Cupón</button>
      </form>

      {editCoupon && (
        <div>
          <h3>Editar cupón</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateCoupon(editCoupon.id, editCoupon);
            }}
          >
            <div>
              <label>
                Code:
                <input
                  type="text"
                  value={editCoupon.code}
                  onChange={(e) => setEditCoupon({ ...editCoupon, code: e.target.value })}
                />
              </label>
            </div>
            <div>
              <label>
                Vencimiento:
                <input
                  type="date"
                  value={editCoupon.expiryDate}
                  onChange={(e) => setEditCoupon({ ...editCoupon, expiryDate: e.target.value })}
                />
              </label>
            </div>
            <div>
              <label>
                Descuento %:
                <input
                  type="number"
                  value={editCoupon.amount}
                  onChange={(e) => setEditCoupon({ ...editCoupon, amount: e.target.value })}
                />
              </label>
            </div>
            <button type="submit">Guardar cambios</button>
            <button type="button" onClick={() => setEditCoupon(null)}>Cancelar</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CouponManager;
