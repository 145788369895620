import "../Navbar/Navbar"
import icono2 from '../../assets/img/icono2.jpeg'

const Header = () => {

    return(
    <div className="miheader"> 
    <img className="img1" src={icono2}/>
    
    </div>)
}

export default Header
