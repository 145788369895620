import React, { useEffect, useState } from "react";
import ItemList from "./ItemList";
import { useParams } from "react-router-dom";
import axios from 'axios';
import NavScrollExample from "../Navbar/Navbar";

const ItemListContainer = () => {
  const { DetalleId } = useParams();
  const [orden, setOrden] = useState('default');
  const [busqueda, setBusqueda] = useState('');
  const [subcategoria, setSubcategoria] = useState(''); // Nuevo estado para la subcategoría seleccionada
  const [productos, setProductos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]); // Estado para almacenar productos filtrados
  const [filtrosVisibles, setFiltrosVisibles] = useState(false); // Estado para controlar si los filtros están visibles

  // Obtener productos al cargar o al cambiar DetalleId
  useEffect(() => {
    const obtenerProductos = async () => {
      try {
        let url = 'https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/products';
        if (DetalleId) {
          url += `/productos/categoria/${DetalleId}`;
        }
        const response = await axios.get(url);
      //  console.log('Datos recibidos del backend:', response.data);
        setProductos(response.data);
      } catch (error) {
        console.error('Error al obtener productos:', error);
      }
    };

    obtenerProductos();
  }, [DetalleId]);

  // Filtrar productos por subcategoría cuando cambie subcategoria
  useEffect(() => {
    const filtrarPorSubcategoria = () => {
      if (subcategoria && productos.length > 0) {
        const productosFiltrados = productos.filter(producto => producto.subcategoria === subcategoria);
        setProductosFiltrados(productosFiltrados);
      } else {
        setProductosFiltrados(productos); // Si no hay subcategoría seleccionada, mantener todos los productos
      }
    };

    filtrarPorSubcategoria();
  }, [subcategoria, productos]);

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const filtrarProductos = () => {
    return productosFiltrados.filter(producto => producto.name.toLowerCase().includes(busqueda.toLowerCase()));
  };

  const ordenarProductos = (productos) => {
    switch (orden) {
      case 'precio-mayor-menor':
        return productos.slice().sort((a, b) => b.price - a.price);
      case 'precio-menor-mayor':
        return productos.slice().sort((a, b) => a.price - b.price);
      case 'a-z':
        return productos.slice().sort((a, b) => a.name.localeCompare(b.name));
      case 'z-a':
        return productos.slice().sort((a, b) => b.name.localeCompare(a.name));
      default:
        return productos;
    }
  };

  // Función para quitar la selección de subcategoría
  const quitarSubcategoria = () => {
    setSubcategoria('');
  };

  // Función para alternar la visibilidad de los filtros
  const toggleFiltros = () => {
    setFiltrosVisibles(!filtrosVisibles);
  };

  return (
    <div className="containtercn">
               <NavScrollExample/>
      <h1 className="tituloinicial">
        Todos los Productos
      </h1>
      <div className="orden-busqueda-container">
        <select value={orden} onChange={handleOrdenChange}>
          <option value="default">Ordenar por...</option>
          <option value="precio-mayor-menor">Precio (mayor a menor)</option>
          <option value="precio-menor-mayor">Precio (menor a mayor)</option>
          <option value="a-z">A-Z</option>
          <option value="z-a">Z-A</option>
        </select>
        <input type="text" value={busqueda} onChange={handleBusquedaChange} placeholder="Buscar por nombre..." />
        {/* Botón para mostrar/ocultar los filtros */}
       <img className="Filter" src=" https://cdn.icon-icons.com/icons2/1993/PNG/512/filter_filters_funnel_list_navigation_sort_sorting_icon_123212.png" onClick={toggleFiltros} />
        {/* Botones de filtro */}
        {filtrosVisibles && (
          <div className="filtros">
            <button onClick={() => setSubcategoria('Acerodorado')}>Acero dorado</button>
            <button onClick={() => setSubcategoria('Aceroblanco')}>Acero blanco</button>
            <button onClick={() => setSubcategoria('Aceroquirurgico')}>Acero quirurgico</button>
            <button onClick={() => setSubcategoria('plata925')}>Plata 925</button>
            {subcategoria && <button onClick={quitarSubcategoria}>Quitar Subcategoría</button>}
          </div>
        )}
      </div>
      <div className="contenedorpadre">
        {productosFiltrados.length === 0 ? (
          <p>No hubo resultados para tu búsqueda.</p>
        ) : (
          <ItemList Productos={ordenarProductos(filtrarProductos())} />
        )}
      </div>
    </div>
  );
};

export default ItemListContainer;
