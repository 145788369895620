import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import './login.css'
import { Navigate } from 'react-router-dom'; 
import NavScrollExample from "../Navbar/Navbar";

function Register() {
  const [email, setEmail] = useState("");
  const [name, setNombre] = useState("");
  const [lastname, setApellido] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); 

  const handleRegister = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }
    
    axios.post('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/users/register', { email, password,lastname,name })
    .then(res => {
      //console.log(res.data);
      setSuccess(true); 
    })
    .catch(error => {
      if (error.response && error.response.status === 409) {
        setError('El correo electrónico ya está registrado');
      } else {
        setError('El correo electrónico ya está registrado');
      }
    });
  }


 
  if (success) {
    return <Navigate to="/" replace />; 
  }

  return (
    
    <div className="login-container2">

      <h2>Registrarse</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleRegister}>
      <div>
          <label htmlFor="name">Nombre:</label>
          <input
            type="name"
            id="name"
            value={name}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="lastname">Apellido:</label>
          <input
            type="lastname"
            id="lastname"
            value={lastname}
            onChange={(e) => setApellido(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
       
        <div>
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Registrarse</button>
      </form>
    </div>
  );
}

export default Register;
