import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import './login.css'; // Importa los estilos CSS

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    axios.post('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/users/login', { email, password })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem('token', res.data.jwt);
          localStorage.setItem('email', email);
          localStorage.setItem('userRole', res.data.role);
          localStorage.setItem('id', res.data.id);
          navigate('/');
          window.location.reload();
        } else {
          setMessage('Credenciales incorrectas');
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          setMessage('Contraseña o email incorrecto');
        } else {
         // console.log(err);
        }
      });
  }

  return (
    <div className="login-container">
      <h2>Login</h2>

      <form onSubmit={handleSubmit}>
        <div>
        {message && <p className="error-message">{message}</p>}
          <label>Email:</label>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Contraseña:</label>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>¿Olvidaste tu contraseña? <Link to="/forgot-password">Recupérala aquí</Link></p> {/* Agrega el enlace para la recuperación de contraseña */}
      <p>No tienes una cuenta? <Link to="/register">Regístrate</Link></p>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Login;
