import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./components.css"
import CartWidget from "../CartWidget/CartWidget"
import { Link } from "react-router-dom";
import User from "../User/User";
import React, { useState,useEffect } from "react";
import Cart from "../Cart/Cart";


function NavScrollExample() {
  
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleUser = () => {
    setIsUserOpen(!isUserOpen);
  };
  return (
    <Navbar expand="lg" style={{ backgroundColor: 'rgb(184, 135, 184)',  marginBottom: '20px'  }} className=" navbar-custom bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/">Accesorios Vilmix</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
         
  
            <NavDropdown title="Productos" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/ItemListContainer/cadenita">Cadenitas </NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/reloj">Reloj</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/pulsera">Pulseras</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/aros">Aros</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/cuff">Cuff</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/tobillera">Tobilleras</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/abridores">Abridores</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/esclavas">Esclavas</NavDropdown.Item>
              <NavDropdown.Item href="/ItemListContainer/lenceria">Lenceria</NavDropdown.Item>
    
            </NavDropdown>
        
          </Nav>
        
          
          <div className="Cart" onClick={toggleCart}>
  <CartWidget />
  {isCartOpen && (
    <div className="cart-dropdown">
      <Cart />
    </div>
  )}
</div>
<div className="Cart" onClick={toggleUser}>

  <User/>
  {isUserOpen && (
    <div >
      {/* Aquí iría el contenido del usuario expandido */}
    </div>
  )}
</div>

        </Navbar.Collapse>
        
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;