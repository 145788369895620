import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import CartItem from "./CartItem";
import axios from "axios";
import './cart.css'
import CartItem2 from "./CartItem2";

const Cart = () => {
    const { cart, totalPrice, clearCart } = useCartContext();

    useEffect(() => {
       ;
    }, [cart]);

   

  
    if (cart.length === 0) {
        return (
            <>  <div className="emptycart">
                <p>El carrito está vacío</p>
                <Link to="/"><button>Ir a La tienda</button></Link>
                </div>
            </>

        );
    }

    return (

        <>
        <div className="cont">
            <div className="contenedor1">
            {cart.map(item => <CartItem2 key={item.id} item={item} />)}
            <h4>Total: $ {totalPrice()}</h4>
            <button onClick={clearCart}>Limpiar Carrito</button>
            <Link to="/Checkout"><button>Ver Carrito</button></Link>
            </div>
            </div>
        </>
    );
};

export default Cart;
