import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import CartItem from "../Cart/CartItem";
import NavScrollExample from "../Navbar/Navbar";

const Checkout = () => {
    const { cart, totalPrice } = useCartContext();
    const [isPaymentCompleted, setPaymentCompleted] = useState(false);
    const navigate = useNavigate(); // Obtén la función navigate

    const handleCheckout = () => {
        // Simulando el proceso de pago completado
        setPaymentCompleted(true);

        // Redirigir al usuario a OrderCheckout
        navigate('/CheckoutOrder');
    };

    return (
        <div className="check-container">
                     <NavScrollExample/>
            <table>
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Nombre</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {cart.map(item => (
                        <CartItem key={item.id} item={item} />
                    ))}
                </tbody>
            </table>
            <div className="total-container">
                <h2>Total: ${totalPrice()}</h2>
                <button onClick={handleCheckout}>Terminar Compra</button>
            </div>
        </div>
    )
}

export default Checkout;

