import React from 'react';
import { Link } from 'react-router-dom'; // Importa el componente Link
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner2 from '../../assets/img/nuevoingreso.png';
import banner from '../../assets/img/nuevosingresos3.png';
import banner3 from '../../assets/img/nuevosingresos6.png';
import './home.css'

const CarouselComponent = () => {
  return (
    <Carousel autoPlay interval={3000} infiniteLoop>
      
      <Link to="/ItemListContainer/nuevosingresos" className="image-link">
      <div>
          <img src={banner2} alt="Image 1" />
      </div>
      </Link>
      <Link  className="image-link">
      <div>
          <img src={banner} alt="Image 1" />
      </div>
      </Link>
      <Link to="/ItemListContainer/nuevosingresos" className="image-link">
      <div>
          <img src={banner3} alt="Image 1" />
      </div>
      </Link>
    </Carousel>
  );
};

export default CarouselComponent;
