import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import './Cards.css';
import { useCartContext } from '../../context/CartContext';

function Cards({ info }) {
  const { addProduct, cart } = useCartContext();
  const [hovered, setHovered] = useState(false); // Estado para controlar si el mouse está sobre el componente

  const onAdd = async () => {
    try {
      // Verificar si el producto ya está en el carrito
      const existingProduct = cart.find(item => item.id === info.id);

      // Calcular la cantidad total deseada
      const totalDesiredQuantity = existingProduct ? existingProduct.cantidad + 1 : 1;

      // Verificar si la cantidad total deseada excede el stock disponible
      if (totalDesiredQuantity > info.stock) {
        alert("No hay suficiente stock disponible para agregar este producto al carrito.");
        return; // Detener la ejecución si no hay suficiente stock
      }

      // Agregar el producto al carrito con la cantidad deseada
      addProduct(info, 1);
    } catch (error) {
      console.error("Error al agregar el producto al carrito:", error);
    }
  };

  return (
    <div className="cardspadre">
      <div className="cardss"
        onMouseEnter={() => setHovered(true)} // Cuando el mouse entra en el componente, establece hovered en true
        onMouseLeave={() => setHovered(false)} // Cuando el mouse sale del componente, establece hovered en false
      >
        <Card.Body className="img">
          <Link to={`/Item/${info.id}`} className="card-link">
            <Card.Img variant="top" src={hovered ? info.thumbnail2 : info.thumbnail}   className={hovered ? "Card-hovered" : "Card"}/>
          </Link>
          {info.stock <= 0 ? ( // Comprueba si el stock es igual a 0
            <div className="sin-stock">Sin Stock</div>
          ) : (
            
            <div className="agregar-carrito" onClick={onAdd}>
              
              Agregar al carrito
            </div>
          )}
        </Card.Body>
        <div className="desc">
          <Link to={`/Item/${info.id}`} className="card-link">
            <h5>{info.name}</h5>
            <h5>${info.price}</h5>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Cards;
