import React, { useState, useEffect } from 'react';

function OrdersComponent() {
  const [newOrders, setNewOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await fetch('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/orders'); // Suponiendo que la ruta de tu API es '/api/orders'
        if (!response.ok) {
          throw new Error('Error al obtener las órdenes');
        }
        const data = await response.json();
        const sortedNewOrders = data.orders.filter(order => !order.armado).sort((a, b) => b.id - a.id);
        const sortedCompletedOrders = data.orders.filter(order => order.armado).sort((a, b) => b.id - a.id);
        setNewOrders(sortedNewOrders);
        setCompletedOrders(sortedCompletedOrders);
      } catch (error) {
        console.error(error);
      }
    }
    fetchOrders();
  }, []);

  const markOrderAsViewed = async (orderId) => {
    try {
      await fetch(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/orders/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ armado: true }),
      });
      // Actualizar la lista de órdenes armadas en el frontend
      const updatedNewOrders = newOrders.filter(order => order.id !== orderId);
      const completedOrder = newOrders.find(order => order.id === orderId);
      setNewOrders(updatedNewOrders);
      setCompletedOrders([...completedOrders, completedOrder]);
    } catch (error) {
      console.error(error);
    }
  };
  const userRole = localStorage.getItem('userRole');
  //console.log(userRole)



  // Verifica el rol del usuario y muestra el componente solo si es un administrador
  if (userRole !== "admin") {
    return <p>No tienes permiso para acceder a esta página.</p>;
  }
  return (
    <div>
      <h2>Órdenes Nuevas</h2>
      {newOrders.length === 0 ? (
        <p>No hay órdenes nuevas.</p>
      ) : (
        <div>
          {newOrders.map(order => (
            <div 
              key={order.id} 
              style={{ 
                border: '1px solid #ccc', 
                marginBottom: '10px', 
                padding: '10px',
                backgroundColor: order.viewed ? '#f0f0f0' : 'white'
              }}
            >
             <p>Nro orden: {order.id}</p>
                <p>Cliente: {order.nombre} {order.apellido}</p>
                <p>DNI: {order.dni}</p>
                <p>Celular: {order.celular}</p>
                <p>Tipo de entrega: {order.tipoEntrega}</p>
                <p>Método de pago: {order.metodoPago}</p>
                <p>Productos:</p>
                {order.products && (
                  <div>
                    {JSON.parse(order.products).map(product => (
                      <p key={product.id}>
                       {product.nombre} x {product.cantidad}
                      </p>
                    ))}
                  </div>
                )}
                <p>Total: $ {order.total}</p>
              {/* Resto de detalles de la orden */}
              {!order.viewed && (
                <button onClick={() => markOrderAsViewed(order.id)}>Marcar como vista</button>
              )}
            </div>
          ))}
        </div>
      )}

      <h2>Órdenes Armadas</h2>
      {completedOrders.length === 0 ? (
        <p>No hay órdenes armadas.</p>
      ) : (
        <div>
          {completedOrders.map(order => (
            <div 
              key={order.id} 
              style={{ 
                border: '1px solid #ccc', 
                marginBottom: '10px', 
                padding: '10px',
                backgroundColor: '#f0f0f0'
              }}
            >
              <p>Nro orden: {order.id}</p>
              <p>Nro orden: {order.id}</p>
                <p>Cliente: {order.nombre} {order.apellido}</p>
                <p>DNI: {order.dni}</p>
                <p>Celular: {order.celular}</p>
                <p>Tipo de entrega: {order.tipoEntrega}</p>
                <p>Método de pago: {order.metodoPago}</p>
                <p>Productos:</p>
                {order.products && (
                  <div>
                    {JSON.parse(order.products).map(product => (
                      <p key={product.id}>
                       {product.nombre} x {product.cantidad}
                      </p>
                    ))}
                  </div>
                )}
                <p>Total: $ {order.total}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OrdersComponent;
